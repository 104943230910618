<template>
  <div>
    <b-card>
      <b-col
        v-if="!dataLoad"
        cols="12"
        md="12"
        class="d-flex align-items-center justify-content-end"
      >
        <MakeSheetExcel
          :need-excel="needExcel"
          :skip-page="skipPage"
        />

        <b-button
          v-if="addType.length"
          ref="actions"
          variant="primary"
          class="ml-auto mr-1"
          @click="goToPageOrOpenModal()"
        >
          <span class="text-nowrap"> {{ addType }} </span>
        </b-button>
      </b-col>

      <searchAndFilterDataTable
        :search-box="searchBox"
        :data-load="dataLoad"
        :show-date-input="HasFilterOptions"
        :get-all-data="getAllData"
        :search-and-select="searchAndSelect"
        @handelSelectedItem="handelSelectedItem"
        @handleSearchItemsArray="handleSearchItemsArray"
      />
      <!-- :selected-item-search-options="SelectedItemSearchOptions" -->
      <b-row>
        <b-col
          cols="8"
          md="8"
        >
          <selectDateReq
            :show-date-input="HasFilterOptions"
            :data-load="dataLoad"
            @handleDate="handleDate"
          />
        </b-col>
        <b-col
          v-if="HasFilterOptions"
          cols="2"
          md="2"
        >
          <SelectSorce
            :data-load="dataLoad"
            @handleSource="handleSource"
          />
        </b-col>
        <b-col
          v-if="!dataLoad"
          cols="2"
          md="2"
        >
          <SelectOffice
            :show-date-input="HasFilterOptions"
            @handleOffice="handleOffice"
          />
        </b-col>
      </b-row>

      <selectCertificateReq
        :selectcertificate="selectcertificate"
        :data-load="dataLoad"
        :certificate-options="certificateOptions"
        :select-subcertificate="selectSubcertificate"
        :get-all-data="getAllData"
        @getIdCertificate="handleCertificateId"
        @getIdSubCertificate="handleSubCertificateId"
      />

      <b-row>
        <b-col md="12">
          <div
            v-if="dataLoad"
            class=" text-center my-2"
          >
            <b-spinner
              type="grow"
              label="Loading..."
            />
          </div>
          <b-table
            v-else
            ref="productsTable"
            :key="tableDataKey"
            :items="rows"
            :fields="columns"
            :current-page="page"
            :per-page="perPage"
            aria-busy="true"
            class="position-relative"
            responsive
            show-empty
            bordered
            primary-key="id"
            :no-local-sorting="true"
          >
            <template
              v-for="(_, slotName) of $scopedSlots"
              v-slot:[slotName]="scope"
            >
              <slot
                :name="slotName"
                v-bind="scope"
              />
            </template>

            <template #cell(actions)="data">
              <slot
                name="actions"
                v-bind="data"
              >
                <div class="space-icons">
                  <PostEntryAction
                    v-if="PostContent"
                    :data="data"
                    :get-all-data="getAllData"
                    :api-url="apiUrl"
                  />

                  <PostContentAction
                    v-if="PostContent"
                    :data="data"
                  />

                  <ViewContentAction
                    v-if="viewContent"
                    :data="data"
                    :viw-component="viwComponent"
                  />

                  <EditContentAction
                    v-if="editContent"
                    :edit-component="editComponent"
                    :data="data"
                    :type="type"
                  />

                  <DeleteContentAction
                    v-if="deleteContent"
                    :data="data"
                    :api-url="apiUrl"
                    :get-all-data="getAllData"
                  />

                  <CancelContentAction
                    v-if="CancelContent"
                    :data="data"
                    :api-url="apiUrl"
                    :get-all-data="getAllData"
                  />

                </div>
              </slot>
            </template>

            <template #cell(name)="data">
              <span style="font-size: smaller; text-wrap: nowrap">
                {{ data.item.name }}
              </span>
            </template>

          </b-table>
        </b-col>

        <!-- //////////////////////////// pagination //////////////////////////// -->
      </b-row>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-between "
          >
            <div>
              Results: <span class="font-weight-bold">{{ totalRows }}</span>
            </div>
            <!-- pagination -->
            <b-pagination
              :value="page"
              :total-rows="totalRows"
              :per-page="perPage"
              align="end"
              first-number
              last-number
              :total-visible="7"
              class="mb-0 mt-1"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
              @input="getAllData($event)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapMutations } from 'vuex'
import decryptData from '@/store/auth/decryptData'
import selectCertificateReq from './filter-by/SelectCertificateReq.vue'
import selectDateReq from './filter-by/SelectDateReq.vue'
import searchAndFilterDataTable from './filter-by/SearchAndFilterDataTable.vue'
import MakeSheetExcel from './specific-action/MakeSheetExcel.vue'
import SelectSorce from './filter-by/SelectSorce.vue'
import SelectOffice from './filter-by/SelectOffice.vue'
import PostEntryAction from './actions/PostEntryAction.vue'
import PostContentAction from './actions/PostContentAction.vue'
import ViewContentAction from './actions/ViewContentAction.vue'
import EditContentAction from './actions/EditContentAction.vue'
import DeleteContentAction from './actions/DeleteContentAction.vue'
import CancelContentAction from './actions/CancelContentAction.vue'

export default {
  components: {
    selectCertificateReq,
    selectDateReq,
    searchAndFilterDataTable,
    MakeSheetExcel,
    SelectSorce,
    SelectOffice,
    PostEntryAction,
    PostContentAction,
    ViewContentAction,
    EditContentAction,
    DeleteContentAction,
    CancelContentAction,
  },
  props: {
    HasFilterOptions: {
      type: Boolean,
      default: () => false,
    },
    apiUrl: {
      type: String,
      default: () => '',
    },
    addType: {
      type: String,
      default: () => '',
    },
    addComponentName: {
      type: String,
      default: () => '',
    },
    editComponent: {
      type: String,
      default: () => '',
    },
    viwComponent: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      default: () => '',
    },
    columns: {
      type: Array,
      default: () => [],
    },
    needExcel: {
      type: Boolean,
      default: () => false,
    },
    viewContent: {
      type: Boolean,
      default: () => true,
    },
    editContent: {
      type: Boolean,
      default: () => true,
    },
    PostContent: {
      type: Boolean,
      default: () => false,
    },
    deleteContent: {
      type: Boolean,
      default: () => true,
    },
    CancelContent: {
      type: Boolean,
      default: () => false,
    },
    searchBox: {
      type: Boolean,
      default: () => true,
    },
    certificateOptions: {
      type: Array,
      default: () => [],
    },
    selectcertificate: {
      type: Boolean,
      default: () => false,
    },
    selectSubcertificate: {
      type: Boolean,
      default: () => false,
    },
    subCertificateOptions: {
      type: Array,
      default: () => [],
    },
    searchAndSelect: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      fromDate: null,
      toDate: null,
      selectedItem: '' || { value: 'all', text: 'All Data' },
      rows: [],
      totalRows: null,
      page: 1,
      dataLoad: false,
      tableDataKey: 0,
      perPage: 20,
      getIdCertificate: null,
      getIdSubCertificate: null,
      skipPage: 0,
      searchItems: [],
      officeName: null,
      sourceName: null,
    }
  },
  mounted() {
    this.getAllData(this.page)
  },
  methods: {
    handleCertificateId(id) {
      this.getIdCertificate = id
    },
    handleSubCertificateId(id) {
      this.getIdSubCertificate = id
    },
    handleDate(fromDate, toDate) {
      this.fromDate = fromDate
      this.toDate = toDate
    },
    handelSelectedItem(item) {
      this.selectedItem = item
    },
    handleSearchItemsArray(search) {
      this.searchItems = search
    },
    handleOffice(name) {
      this.officeName = name
    },
    handleSource(name) {
      this.sourceName = name
    },
    goToPageOrOpenModal() {
      if (this.type === 'page') {
        this.$router.push({ name: this.addComponentName })
      } else {
        this.$parent.$refs.genmodal.show()
      }
    },

    ...mapMutations('app', ['setShowFilterRow']),

    async getAllData(page = 1) {
      this.setShowFilterRow(false)

      const searchQuery = this.$route.query.search
      // console.log(this.searchQuery)

      this.dataLoad = true
      try {
        const skip = (page - 1) * this.perPage
        this.skipPage = skip
        const params = {
          skip,
          certificate: this.getIdCertificate,
          sub: this.getIdSubCertificate,
          ...(this.fromDate && { from: this.fromDate }),
          ...(this.toDate && { to: this.toDate }),
          ...(this.officeName && { office: this.officeName }),
          ...(this.sourceName && { source: this.sourceName }),
          // eslint-disable-next-line
          ...(this.searchItems && this.searchItems.length > 0
            ? this.searchItems.reduce((acc, item) => {
              acc[this.selectedItem.value] = searchQuery || ''
              if (item.searchQuery && item.selectedOption) {
                acc[item.selectedOption.value] = item.searchQuery || ''
              }
              return acc
            }, {})
            // eslint-disable-next-line
            : this.HasFilterOptions ? { [this.selectedItem.value]: searchQuery }
              : (searchQuery ? { search: searchQuery } : {})),
        }

        const response = await axios.get(this.apiUrl, { params })

        if (response.status === 200) {
          const responseData = decryptData(response.data.payload)
          // console.log(responseData)
          this.setShowFilterRow(true)
          this.rows = responseData?.data[Object.keys(responseData?.data)[0]]
          this.totalRows = responseData.data.count || responseData.data.length
        }
      } catch (error) {
        if (error.response.status === 500) {
          // console.log(decryptData(error.response.data.payload))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server Error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.dataLoad = false
        // this.setShowFilterRow(false)
      }
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
