<template>
  <span title="View">
    <feather-icon
      icon="EyeIcon"
      class="cursor-pointer"
      @click="
        $router.push({
          name: 'PostEntry',
          params: { id: data.item.id }
        })
      "
    />
  </span>
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
