<template>
  <span
    title="Cancel"
  >
    <feather-icon
      icon="SlashIcon"
      class="cursor-pointer"
      @click="CancelEntry(data.item.id)"
    />
  </span>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    apiUrl: {
      type: String,
      default: () => '',
    },
    getAllData: {
      type: Function,
      default: () => {},
    },
  },
  methods: {

    CancelEntry(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, cancel it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .put(`${this.apiUrl}/${id}/cancel`)
            .then(() => {
              this.$swal('Cancel!', 'Cancel Successfully.', 'success')
              this.getAllData()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
  },
}
</script>
