<template>
  <span title="Delete">
    <feather-icon
      id="popover-button-1"
      icon="TrashIcon"
      class="cursor-pointer"
      @click="deleteRow(data.item.id)"
    />
  </span>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    apiUrl: {
      type: String,
      default: () => '',
    },
    getAllData: {
      type: Function,
      default: () => {},
    },
  },
  methods: {

    deleteRow(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          const apiUrlUsed = this.apiUrl.indexOf('?') !== -1 ? this.apiUrl.slice(0, this.apiUrl.indexOf('?')) : this.apiUrl
          axios
            .delete(`${apiUrlUsed}/${id}`)
            .then(() => {
              this.$swal('Deleted!', 'Deleted Successfully.', 'success')
              this.getAllData()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
  },
}
</script>
