<template>
  <b-row v-if="ShowDateInput">
    <b-col
      v-if="localDataLoad"
      cols="12"
      class="d-flex align-items-center justify-content-center"
    >
      <!-- Loading Indicator -->
      <b-spinner label="Loading..." />
    </b-col>
    <b-form-group
      v-if="!localDataLoad"
      label="office "
    >
      <b-form-select
        v-model="office_id"
        :options="optionsOffice"
        trim
        @change="$emit('handleOffice', office_id)"
      />

    </b-form-group>
  </b-row>
</template>

<script>
import axios from 'axios'
import decryptData from '@/store/auth/decryptData'

export default {
  props: {
    ShowDateInput: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      optionsOffice: [],
      office_id: '',
      localDataLoad: false, // Create a local copy of the prop

    }
  },
  mounted() {
    this.getOffice()
  },
  methods: {

    getOffice() {
      if (this.ShowDateInput) {
        this.localDataLoad = true
        axios.get('office').then(res => {
          this.optionsOffice = decryptData(res.data.payload).data.offices.map(item => ({
            value: item.name,
            text: item.name,
          }))
        }).finally(() => {
          this.localDataLoad = false
        })
      }
    },
  },
}
</script>
