<template>
  <span title="Post">
    <feather-icon
      icon="CheckCircleIcon"
      class="cursor-pointer"
      @click="PostEntry(data.item.id)"
    />
  </span>
</template>

<script>
import axios from '@axios'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    apiUrl: {
      type: String,
      default: () => '',
    },
    getAllData: {
      type: Function,
      default: () => {},
    },
  },

  methods: {
    PostEntry(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Post it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .put(`${this.apiUrl}/${id}/post`)
            .then(() => {
              this.$swal('Posted!', 'Posted Successfully.', 'success')
              this.getAllData()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
  },

}
</script>
