<template>
  <b-form-group
    v-if="!dataLoad"
    label="Source"
  >
    <b-form-select
      v-model="SourceName"
      :options="optionsSource"
      trim
      @change="$emit('handleSource', SourceName)"
    />

  </b-form-group>
</template>

<script>

export default {
  props: {
    dataLoad: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      optionsSource: [
        { value: 'saber', text: 'Saber' },
        { value: 'saber ME', text: 'Saber ME' },
        { value: 'saber AA', text: 'Saber AA' },
        { value: 'saber EU', text: 'Saber EU' },
        { value: 'jeem', text: 'Jeem 1' },
        { value: 'sfda', text: 'SFDA' },
        { value: 'moiat', text: 'Moiat' },
        { value: 'sls', text: 'SLS' },
      ],
      SourceName: '',
    }
  },
}
</script>
