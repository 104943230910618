<template>
  <img
    v-if="needExcel"
    src="@/assets/images/icons/excel.png"
    style="width: 70px; height: 70px; cursor: pointer;"
    @click="makeSheetExcel"
  >
</template>
<script>

import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props: {
    needExcel: {
      type: Boolean,
      default: () => false,
    },
    skipPage: {
      type: Number,
      default: () => 0,
    },
  },

  methods: {

    async makeSheetExcel() {
      try {
        const searchExcel = this.$route.query.search || ''
        const response = await axios.get(`late-reasons?search=${searchExcel}&skip=${this.skipPage}&excel=late-reasons`, {
          responseType: 'blob',
        })

        if (response.status === 200) {
          const now = new Date()
          const formattedDate = now.toISOString().split('T')[0]
          const formattedTime = now.toTimeString().split(' ')[0].replace(/:/g, '-')
          const dateTimeString = `late reason ${formattedDate}_${formattedTime}`

          const blob = new Blob([response.data], { type: response.data.type })
          const url = window.URL.createObjectURL(blob)

          const link = document.createElement('a')
          link.href = url
          link.download = dateTimeString
          link.click()

          this.$toasted.show('Updated Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${error}`,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      }
    },

  },
}
</script>
