<template>
  <span title="View">
    <feather-icon
      icon="EyeIcon"
      class="cursor-pointer"
      @click="viewPage(data.item)"
    />
  </span>
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    viwComponent: {
      type: String,
      default: () => '',
    },
  },

  methods: {
    viewPage(data) {
      if (this.viwComponent) {
        this.$router.push({ name: this.viwComponent, params: { id: data.id } })
        this.$store.commit('generalIds/SET_ID', data.id)
      } else {
        this.$parent.$refs['view-modal'].show()
        this.$store.commit('generalIds/SET_ID', data.id)
      }
    },
  },
}
</script>
